// // import './App.css';

// import Login from "./components/auth/Login";
// import MainLayout from "./components/common/MainLayout";
// import GlobalPartner from "./components/globalpartner/GlobalPartner";
// import CreateNewPassword from "./forms/auth/CreateNewPassword";
// import OtpVerification from "./forms/auth/OtpVerification";
// import RegistrationForm from "./forms/auth/RegistrationForm";
// import ResetPasswordForm from "./forms/auth/ResetPasswordForm";
// import RoutesPath from "./routes/RoutesPath";

// function App() {
//   return (
//     <>
//    {/* <div className="App"> */}
//    <MainLayout/>
//    {/* <RoutesPath/> */}
//      {/* <Login /> */}
// {/* <RegistrationForm/> */}
// {/* <CreateNewPassword/> */}
//  {/* <ResetPasswordForm/> */}
//  {/* <OtpVerification/> */}
//  {/* <GlobalPartner/> */}
//      {/* </div> */}
//     </>
//   );
// }

// export default App;

import React, { lazy } from "react";
import "./App.css";


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmailProvider } from "./pages/home/HomeGlobalEmail";
// const Login = lazy(() => import("./components/auth/Login"));

const HeaderLayout = lazy(() => import("./components/common/HeaderLayout"));
const RoutesPath = lazy(() => import("./routes/RoutesPath"));
const FooterLayout = lazy(() => import("./components/common/FooterLayout"));

function App() {
  return (
    <>
      <HeaderLayout />
      <div className="app-container">
        <div className="left-background"></div>
        <div className="right-background"></div>
        <div className="content">
        <EmailProvider>
          <RoutesPath />
          <FooterLayout />
          </EmailProvider>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
